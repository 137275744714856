<div>
  <div (click)="onClickedView(tech)" class="col border border-color border-solid rounded">
    <div class="card  border-0 h-100">
      <div class="picture-box float-left">
        <img
          [src]="tech?.imageUrl"
          class="card-img-top height-image img-fluid"
          [alt]="tech?.name"
        />
      </div>
      <div  class="card-body d-flex flex-column clearfix d-flex justify-content-center position-relative">
        <div class="d-flex gap-2 justify-content-center align-items-center ">
          <span
            *ngIf="tech?.ecosystems?.[0]"
            class="badge margins-tech me-2 tech-badge"
          >{{ tech?.ecosystems?.[0]?.name }}</span>
          <h3 class="card-title">{{ tech?.name }}</h3>
        </div>
        <div class="d-flex flex-row align-items-center">
          <p class="card-text">{{ tech?.description }}</p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="tech && tech.id !== undefined && groupedButtonInfo">
      <tech-interaction-card [buttonInfo]="buttonInfo"></tech-interaction-card>
    </ng-container>
  <div class="card-footer">
    <div class="d-flex tag-container flex-wrap gap-1 p-2">
      <span class="bi bi-tags mr-2"></span>
      <span *ngFor="let tag of tech?.tags" class="badge color-tags gap" [style.background-color]="tag.tagGenre?.backgroundColor" [style.color]="tag.tagGenre?.textColor">{{
        tag.name
      }}</span>
    </div>
  </div>
  </div>
</div>

