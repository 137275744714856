import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";

@Component({
  standalone: true,
  imports: [MatButtonModule, CommonModule],
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})
export class TagComponent implements OnInit {
  @Input() isSelected: boolean= false;
  @Input() name: string = '';
  @Input() backgroundColor: string = '#6C757D';
  @Input() textColor: string = '#FFFFFF';

  constructor() {
    // Empty constructor for structural purposes
  }

  ngOnInit(): void {
    // Empty ngOnInit for structural purposes
  }
}