import { Injectable } from '@angular/core';
import { isTokenMessage } from '../jwt-loader/utils';
import { ReadyMessage } from '../jwt-loader/types';
import { BehaviorSubject } from 'rxjs';
import { appname } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JwtProviderService {
  tokenSubject = new BehaviorSubject<string>('');
  $token = this.tokenSubject.asObservable();

  addTokenListener(): void {
    const storedToken = window.sessionStorage.getItem('JWT')
    if (storedToken) this.tokenSubject.next(storedToken)
    window.onmessage = (e: MessageEvent) => {
      const data = e.data;
      if (isTokenMessage(data)) {
        this.tokenSubject.next(data.payload.token);
        window.sessionStorage.setItem('JWT', data.payload.token)
      }
    };
  }

  sendReadyMessage(): void {
    const readyMessage: ReadyMessage = {
      messageType: 'ready',
      payload: {
        appName: appname,
        appBarItems: [{
          icon: "developer_board",
          label: "Add Tech",
          url: "/tech/add",
        },
        {
          icon: "sell",
          label: "Tags",
          url: "/tag/",
        },
      ],
      },
    };
    window.parent.postMessage(readyMessage, '*');
  }
}
