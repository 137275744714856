import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { inIframe } from './common/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    // This check is necessary to run the module locally without the shell
    if (inIframe()) {
      this.router.events.subscribe((value) => {
        if (value instanceof NavigationEnd) {
          window.parent.postMessage({ messageType: 'route', payload: { route: value.url } }, '*');
        }
      });
    }
  }
}
