import { AsyncPipe } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CollapsibleComponent } from '../collapsible/collapsible.component';
import { Tech } from '../models/models';
import { TechService } from '../services/tech.service';

@Component({
  selector: 'app-inbox',
  standalone: true,
  imports: [CollapsibleComponent, AsyncPipe],
  templateUrl: './inbox.component.html',
  styleUrl: './inbox.component.css'
})
export class InboxComponent implements OnInit, OnDestroy {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private techService = inject(TechService);
  
  inboxedTechs: Tech[] | null = null;
  inboxedTechsSub: Subscription | null = null;

  ngOnInit(): void {
    this.inboxedTechsSub = this.techService.getTechsInbox()
      .subscribe((apiResponse) => {
        this.inboxedTechs = apiResponse.data || [];
      })
  }

  async goToTech(slug: string) {
    await this.router.navigate([`edit/${slug}`], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.inboxedTechsSub?.unsubscribe()
  }
}
