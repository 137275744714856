<div
  (click)="onClickedView(tech)"
  class="card shadow-sm d-flex flex-column"
  [class.archived]="tech?.isArchived"
  [class.opacity-low]="tech?.isArchived"
  style="height: 250px; cursor: pointer"
  >
  <div class="card-body p-0 d-flex flex-column flex-grow-1">
    <!-- TECH NAME -->
    <div class="d-flex m-2 mb-0 gap-2 align-items-center justify-content-center">
      <span class="card-title h4 text-center">{{ tech?.name }}</span>
    </div>
    <!-- TECH IMAGE -->
    <div
      class="flex-grow-1 p-1 w-100 d-flex justify-content-center align-items-center"
      style="height: 0"
      >
      <img [src]="tech?.imageUrl" class="tech-image" [alt]="tech?.name" loading="lazy" />
    </div>
  </div>
  <!-- TECH FOOTER -->
  <div class="card-footer px-2 py-1 d-flex flex-row justify-content-between align-items-center">
    <!-- TECH DESCRIPTION + TOOLTIP -->
    <app-tooltip id="tltp-descr-{{ tech?.id }}">
      <span class="bi bi-info icon-styling h3 mb-0"></span>
      <div tooltip class="bg-white border rounded shadow">
        <div class="border-top border-bottom bg-light px-2">Description</div>
        <div class="p-2 pl-1"> {{ tech?.description }}</div>
      </div>
    </app-tooltip>
    <!-- TECH TAGS + TOOLTIP -->
    <app-tooltip id="tltp-tags-{{ tech?.id }}">
      <span
        class="bi bi-tags icon-styling h5 mb-0"
        [style.opacity]="tech?.tags?.length ? 1 : 0.2"
      ></span>
      <div tooltip class="bg-white border rounded d-flex flex-column shadow">
        @for (genre of tagsByGenreDictionary; track genre) {
          <div class="pb-1">
            <div class="border-top border-bottom bg-light px-3">{{ genre.name }}</div>
            <ul class="mr-1 mb-0" style="padding-right: 1rem">
              @for (tag of genre.tags; track tag) {
                <li>
                  {{ tag.name }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    </app-tooltip>
    <!-- TECH ECOSYSTEM + TOOLTIP -->
    <app-tooltip id="tltp-ecosystem-{{ tech?.id }}">
      <span
        class="bi bi-globe icon-styling h5 mb-0"
        [style.opacity]="tech?.ecosystems?.[0] ? 1 : 0.2"
      ></span>
      @if (tech?.ecosystems?.[0]?.name) {
        <div tooltip class="bg-white border rounded  shadow">
          <div class="border-top border-bottom bg-light px-3">Ecosystem</div>
          <div class="px-3">  {{ tech?.ecosystems?.[0]?.name }}</div>
        </div>
      }
    </app-tooltip>
    <!-- TECH LIKES -->
    <tech-interaction-card [buttonInfo]="buttonInfo"></tech-interaction-card>
  </div>
</div>
