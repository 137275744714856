<div class="d-flex justify-content-center">
  <div class="box">
    <div class="left-column my-5 d-none d-md-block">
      <div>
        <app-tag-selector 
          (tagFilters)="selectedTags = $event" 
          (ecosystemFilters)="selectedEcosystems = $event" 
          (searchFilter)="handleSearchValue($event)">
        </app-tag-selector>
      </div>
    </div>
    <div class="right-column m-3 my-5">
      <div class="" *ngIf="id === null">
        <div class="d-flex flex-wrap justify-content-center align-items-center mobile w-100 my-4">
          <div class="input-group w-100 mb-3">
            <span class="input-group-text"><em class="bi bi-search"></em></span>
            <input type="text" class="form-control" placeholder="Search" [(ngModel)]="filter">
          </div>
          <div class="d-flex flex-wrap justify-content-between w-100">
            <div class="d-flex gap-1 align-items-baseline">
              <select class="form-select" id="ecosystem" #id (change)="setEcosystemFilter(id.value)" [ngModel]="selectedEcosystem">
                <option [value]="ALL_VALUES">Ecosystems</option>
                <option *ngFor="let ecosystem of ecosystems" [value]="ecosystem.id">{{ ecosystem.name }}</option>
              </select>
            </div>
            <div class="d-flex gap-1 align-items-baseline">
              <select class="form-select" id="tag" #tagid (change)="setTagFilter(tagid.value)" [ngModel]="selectedTag">
                <option [value]="ALL_VALUES">All tags</option>
                <option *ngFor="let tag of tags" [value]="tag.id">{{ tag.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="desktop-card album pb-5">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-3">
            <div class="col" *ngFor="let tech of filteredTechs">
              <ng-container *ngIf="tech && tech.id !== undefined && groupedButtonInfo">
                <overview-desktop-card [tech]="tech" [buttonInfo]="groupedButtonInfo[tech.id]"></overview-desktop-card>
              </ng-container>
            </div> 
          </div>
        </div>
        <div class="mobile-card album pb-5">
          <div class="row row-cols-1 row-cols-md-2 g-3">
            <div *ngFor="let tech of filteredTechs">
              <ng-container *ngIf="tech && tech.id !== undefined && groupedButtonInfo">
                <overview-mobile-card [tech]="tech" [buttonInfo]="groupedButtonInfo[tech.id]"></overview-mobile-card>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>