<div class="container card shadow-sm mt-3">
  <div class="card-body p-3">
    <div class="row">
      <div class="col-3 d-flex align-items-center my-auto">
        <a [href]="tech?.url" target="_blank">
          <img
            class="img-fluid object-fit-scale"
            width="200"
            height="200"
            [src]="tech?.imageUrl"
            [alt]="tech?.name"
          />
        </a>
      </div>
      <div class="col-9">
        <div class="d-flex gap-2 align-items-center">
          <h1>
            <a class="text-decoration-none" target="_blank" [href]="tech?.url">{{ tech?.name }}</a>
          </h1>
          <h4>
            <span *ngIf="tech?.ecosystems?.[0]" class="badge bg-secondary my-2 me-2">
              {{ tech?.ecosystems?.[0]?.name }}
            </span>
          </h4>
        </div>
        <div *ngIf="tech?.tags && tech?.tags!.length > 0" class="d-flex flex-wrap gap-1">
          <span class="bi bi-tags mr-2"></span>
          <span *ngFor="let tag of tech?.tags" class="badge bg-secondary gap">{{ tag.name }}</span>
        </div>
        <hr />
        <p class="card-text">{{ tech?.description }}</p>
      </div>
    </div>
    <div class="d-flex mt-5">
      <button class="btn btn-sm btn-outline-primary" (click)="onEditTech()">
        <span class="bi bi-pencil-fill"></span> Edit
      </button>
      <button class="btn btn-sm mx-1" [class.btn-danger]="!isArchived" [class.btn-success]="isArchived" (click)="onArchiveTech()" attr.aria-label="{{ isArchived ? 'Unarchive Tech' : 'Archive Tech' }}">
        <span class="bi bi-archive"></span>
        {{ isArchived ? 'Unarchive' : 'Archive' }}
      </button>
      <button class="btn btn-sm btn-outline-secondary" (click)="back()">Back</button>
      <div class="ms-auto" *ngIf="isArchived">
        <button class="btn btn-sm btn-danger" (click)="onDeleteTech()">Delete</button>
      </div>
    </div>
  </div>
</div>
