import { Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { ButtonInfo } from '../models/models';

@Component({
  standalone: true,
  imports: [MatBadgeModule],
  selector: 'tech-interaction-card',
  templateUrl: './tech-interaction-card.component.html',
  styleUrls: ['./tech-interaction-card.component.css'],
})
export class TechInteractionCardComponent {
  @Input() buttonInfo?: ButtonInfo;

  constructor(
  ) { }

  ngOnInit(): void {
  }
}