<button class="card h-100 border-0 w-100">
  <div 
    (click)="onClickedView(tech)" 
    class="card shadow-sm h-100 w-100"
    [class.archived]="tech?.isArchived"
    [class.opacity-low]="tech?.isArchived">
    <img
      [src]="tech?.imageUrl"
      class="card-img-top height-image img-fluid"
      style="object-fit: scale-down"
      [alt]="tech?.name"
    />
    <div class="card-body d-flex flex-column">
      <div class="d-flex gap-2 align-items-center">
        <h3 class="card-title">{{ tech?.name }}</h3>
        <span
          *ngIf="tech?.ecosystems?.[0]"
          class="badge bg-secondary margins-tech me-2"
          >{{ tech?.ecosystems?.[0]?.name }}</span
        >
      </div>
      <p class="card-text">{{ tech?.description }}</p>
    </div>
    <ng-container *ngIf="tech && tech.id !== undefined && groupedButtonInfo">
      <tech-interaction-card [buttonInfo]="buttonInfo"></tech-interaction-card>
    </ng-container>
    <div class="card-footer">
      <div class="d-flex flex-wrap gap-1">
        <span class="bi bi-tags mr-2"></span>
        <span *ngFor="let tag of tech?.tags">
          <span class="badge gap" [style.background-color]="tag.tagGenre?.backgroundColor" [style.color]="tag.tagGenre?.textColor">{{
            tag.name
          }}</span>
        </span>
      </div>
    </div>
  </div>
</button>
