<!-- <div>
    <em class="bi bi-chat-left-fill icon-styling h4"  matBadge="1 {{buttonInfo?.commentCount}}" matBadgePosition="after" matBadgeColor="accent"></em>
  </div> -->

<span
  class="bi bi-heart-fill icon-styling h4 mb-0"
  matBadge="{{ buttonInfo?.reactionCount || undefined }}"
  matBadgePosition="after"
  matBadgeColor="accent"
></span>
