import { Component, Input, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { ButtonInfo, Tag, TagGenre, TechDetail } from '../models/models';
import { TagSelectorComponent } from '../tag-selector/tag-selector.component';
import { TechInteractionCardComponent } from '../tech-interaction-card/tech-interaction-card.component';
import { TooltipComponent } from '../tooltip/tooltip.component';

export type TagGenreForTooltip = TagGenre & { tags: Array<Tag>, tagsTooltip: string}
type GenreDictionary = Record<string, TagGenreForTooltip>

@Component({
  standalone: true,
  imports: [TooltipComponent, TechInteractionCardComponent, TagSelectorComponent],
  selector: 'overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.css'],
})
export class OverviewCardComponent {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  @Input() tech: TechDetail | null = null;
  @Input() buttonInfo?: ButtonInfo;

  groupedButtonInfo: Record<string, ButtonInfo> = {};

  trackByFn = (index: number, genre: TagGenreForTooltip) => genre.id;

  get tagsByGenreDictionary() {
    const NO_GENRE_ID = "no_genre"
    const dict = this.tech?.tags?.reduce((genreDict, tag) => {
      const { tagGenre, ...tagWithoutGenre } = tag
      if (!tagGenre) {
        genreDict[NO_GENRE_ID].tags.push(tagWithoutGenre)
        return genreDict
      }
      if (!genreDict[tagGenre.id!]) {
        genreDict[tagGenre.id!] = {
          ...tagGenre,
          tagsTooltip: '',
          tags: []
        }
      }
      genreDict[tagGenre.id!].tags.push(tagWithoutGenre)
      return genreDict
    }, {
      [NO_GENRE_ID]: {
        name: "No Genre",
        textColor: '',
        backgroundColor: '',
        tagsTooltip: '',
        tags: []
      }
    } as GenreDictionary)
    
    return Object.values(dict || {}).filter(genre=>genre.tags.length)
  }
  
  async onClickedView(tech: TechDetail | null) {
    await this.router.navigate([tech?.slug], { relativeTo: this.route });
  }

  async onClickedEditTag(tag?: TechDetail | null) {
    await this.router.navigate([`/tag/edit/${tag?.slug ?? ''}`]);
  }
}