import { NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { inIframe } from './common/utils';
import { JwtLoaderComponent } from './core/authentication/jwt/jwt-loader/jwt-loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, NgTemplateOutlet, JwtLoaderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  private router = inject(Router);


  ngOnInit(): void {
    // This check is necessary to run the module locally without the shell
    if (inIframe()) {
      this.router.events.subscribe((value) => {
        if (value instanceof NavigationEnd) {
          window.parent.postMessage({ messageType: 'route', payload: { route: value.url } }, '*');
        }
      });
    }
  }
}

