import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechDetail } from '../models/models';
import { TechService } from '../services/tech.service';


@Component({
    selector: 'app-tech-detail',
    templateUrl: './tech-detail.component.html',
    styleUrls: ['./tech-detail.component.css'],
    standalone: true,
    imports: [],
})
export class TechDetailComponent implements OnInit {
  private techService = inject(TechService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  slug!: string;
  tech?: TechDetail;
  isArchived?: boolean;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.slug = params['slug'];
      if (this.slug !== null) {
        this.techService.getTechDetailBySlug(this.slug).subscribe({
          next: (tech) => {
            if (tech.data) {
              this.tech = tech.data;
            }
            if (tech.error) {
              return;
            }
            if (tech.data) {
              this.tech = tech.data;
            }
          },
          error: (e) => {},
        });
      }
    });
  }

  async onEditTech() {
    await this.router.navigate([`tech/edit/${this.slug}`]);
  }

  async onDeleteTech() {
    const confirmed = confirm("Are you sure you want to permanently delete this technology?");
    if (confirmed) {
      const confirmed2 = confirm("This action is irreversible, are you SURE?");
      if (confirmed2) {
        this.executeDeleteTech();
      }
    }
  }

  async executeDeleteTech() {
    if (this.tech?.id) {
      this.techService.deleteTech(this.tech.id).subscribe();
      await this.router.navigate(['/techs']);
    } else {
      alert('No Current Tech');
    }
  }

  async onArchiveTech() {
    if (this.tech?.id) {
      this.techService.archiveTech(this.tech.id).subscribe();
      await this.router.navigate(['/tech']);
    } else {
      alert('No Current Tech');
    }
  }

  async back(): Promise<void> {
    await this.router.navigate([".."]);
  }
}
