import { Component, ContentChild, OnInit, TemplateRef, inject } from '@angular/core';
import { JwtProviderService } from '../jwt-provider/jwt-provider.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-jwt-loader',
    templateUrl: './jwt-loader.component.html',
    styleUrls: ['./jwt-loader.component.scss'],
    standalone: true,
    imports: [
    NgTemplateOutlet,
    AsyncPipe
],
})
export class JwtLoaderComponent implements OnInit {
  private tokenProvider = inject(JwtProviderService);
  private router = inject(Router);

  @ContentChild(TemplateRef) template: any;
  $token: Observable<string>;

  constructor() {
    const tokenProvider = this.tokenProvider;

    this.$token = tokenProvider.$token;
  }

  ngOnInit(): void {
    this.tokenProvider.addTokenListener();
    this.tokenProvider.sendReadyMessage();

    window.addEventListener('message', async ({data}: MessageEvent) => {
      if (data.messageType !== 'route') return;
      await this.router.navigate([data.payload.route]);
    });
  }
}
