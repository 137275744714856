import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayApiResponse, ButtonInfo, TechDetail } from '../models/models';

@Component({
  selector: 'overview-mobile-card',
  templateUrl: './overview-mobile-card.component.html',
  styleUrls: ['./overview-mobile-card.component.css'],
})
export class overviewMobileCardComponent implements OnInit, OnDestroy {
  @Input () tech: TechDetail | null = null;
  @Input() buttonInfo?: ButtonInfo;

  groupedButtonInfo: Record<string, ButtonInfo> = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {

  }

  async onClickedView(tech: TechDetail | null) {
    await this.router.navigate([tech?.id], { relativeTo: this.route });
  }
}

const mapResponse = <TResponse>(response: ArrayApiResponse<TResponse>): TResponse[] => !response.error ? (response.data ? response.data : []) : [];
