<div class="d-flex justify-content-center m-3 gap-2">
  <div class="left-column w-20 d-none d-md-block gap-2 d-flex">
    <app-inbox></app-inbox>
    <app-tag-selector
      (tagFilters)="selectedTags = $event"
      (ecosystemFilters)="selectedEcosystems = $event"
      (searchFilter)="handleSearchValue($event)"
      >
    </app-tag-selector>
  </div>
  <div class="right-column">
    @if (!techs) {
      <div>
        <mat-spinner color="accent"></mat-spinner>
      </div>
    }
    @if (techs) {
      <div>
        @if (id === null) {
          <div class="d-flex flex-column gap-1">
            <div>
              <app-pagination-buttons
                [searchParamName]="paginationName"
                [totalElements]="filteredTechs?.length"
                [elementsPerPage]="pageSize"
              ></app-pagination-buttons>
            </div>
            <div class="d-flex flex-wrap justify-content-center align-items-center mobile w-100 my-4">
              <div class="input-group w-100 mb-3">
                <span class="input-group-text"><em class="bi bi-search"></em></span>
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="filter" />
              </div>
              <div class="d-flex flex-wrap justify-content-between w-100">
                <div class="d-flex gap-1 align-items-baseline">
                  <select
                    class="form-select"
                    id="ecosystem"
                    #id
                    (change)="setEcosystemFilter(id.value)"
                    [ngModel]="selectedEcosystem"
                    >
                    <option [value]="ALL_VALUES">Ecosystems</option>
                    @for (ecosystem of ecosystems; track ecosystem) {
                      <option [value]="ecosystem.id">
                        {{ ecosystem.name }}
                      </option>
                    }
                  </select>
                </div>
                <div class="d-flex gap-1 align-items-baseline">
                  <select
                    class="form-select"
                    id="tag"
                    #tagid
                    (change)="setTagFilter(tagid.value)"
                    [ngModel]="selectedTag"
                    >
                    <option [value]="ALL_VALUES">All tags</option>
                    @for (tag of tags; track tag) {
                      <option [value]="tag.id">{{ tag.name }}</option>
                    }
                  </select>
                </div>
              </div>
            </div>
            <div class="desktop-card album pb-5">
              <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 g-2">
                @for (tech of pagedTechs; track tech) {
                  <div class="col">
                    @if (tech && tech.id !== undefined && groupedButtonInfo) {
                      <overview-card
                        [tech]="tech"
                        [buttonInfo]="groupedButtonInfo[tech.id]"
                      ></overview-card>
                    }
                  </div>
                }
              </div>
            </div>
            <div>
              <app-pagination-buttons
                [searchParamName]="paginationName"
                [totalElements]="filteredTechs?.length"
                [elementsPerPage]="pageSize"
              ></app-pagination-buttons>
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
