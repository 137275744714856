import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorUtilityService {

  constructor() { }

  /**
   * Converts a hexadecimal color representation to its RGB equivalent.
   * @param hex The hexadecimal color code (e.g., '#RRGGBB').
   * @returns An object containing the RGB representation of the color.
   */
  hexToRgb(hex: string): { r: number, g: number, b: number } {
    const bigint = parseInt(hex.substring(1), 16);
    return { r: (bigint >> 16) & 255, g: (bigint >> 8) & 255, b: bigint & 255 };
  }

  /**
   * Calculates the contrast ratio between two colors based on their luminance values.
   * @param color1 An object representing the RGB values of the first color.
   * @param color2 An object representing the RGB values of the second color.
   * @returns The contrast ratio between the two colors as a number.
   */
 getContrastRatio(color1: { r: number, g: number, b: number }, color2: { r: number, g: number, b: number }): number {
    const luminance1 = this.calculateLuminance(color1.r, color1.g, color1.b);
    const luminance2 = this.calculateLuminance(color2.r, color2.g, color2.b);
    const brighter = Math.max(luminance1, luminance2);
    const darker = Math.min(luminance1, luminance2);
    return (brighter + 0.05) / (darker + 0.05);
  }

  /**
   * Calculates the relative luminance of a color according to the WCAG 2.0 guidelines.
   * @param r The red component of the color (0-255).
   * @param g The green component of the color (0-255).
   * @param b The blue component of the color (0-255).
   * @returns The relative luminance of the color.
   */
  calculateLuminance(r: number, g: number, b: number): number {
    const rsrgb = r / 255;
    const gsrgb = g / 255;
    const bsrgb = b / 255;
    const rLinear = rsrgb <= 0.03928 ? rsrgb / 12.92 : Math.pow((rsrgb + 0.055) / 1.055, 2.4);
    const gLinear = gsrgb <= 0.03928 ? gsrgb / 12.92 : Math.pow((gsrgb + 0.055) / 1.055, 2.4);
    const bLinear = bsrgb <= 0.03928 ? bsrgb / 12.92 : Math.pow((bsrgb + 0.055) / 1.055, 2.4);
    return 0.2126 * rLinear + 0.7152 * gLinear + 0.0722 * bLinear;
  }

  /**
   * Determines the best contrasting text color for a given background color.
   * @param backgroundColor The background color for which to determine the text color.
   * @returns Either '#000000' (black) or '#FFFFFF' (white) based on which provides a better contrast ratio.
   */
  chooseBestContrastColor(backgroundColor: string): string {
    const backgroundRgb = this.hexToRgb(backgroundColor);
    const blackContrast = this.getContrastRatio({ r: 0, g: 0, b: 0 }, backgroundRgb);
    const whiteContrast = this.getContrastRatio({ r: 255, g: 255, b: 255 }, backgroundRgb);

    if (blackContrast > whiteContrast) {
      return '#000000';
    } else {
      return '#FFFFFF';
    }
  }

  /**
   * Calculates the contrast ratio between a background color and a text color.
   * @param backgroundColor The background color.
   * @param textColor The text color.
   * @returns The contrast ratio between the background and text colors as a number.
   */
  calculateContrastRatio(backgroundColor: string, textColor: string): number {
    const backgroundRgb = this.hexToRgb(backgroundColor);
    const textRgb = this.hexToRgb(textColor);
    
    return this.getContrastRatio(backgroundRgb, textRgb);
  }
}
