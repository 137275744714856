import { Injectable, inject } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { map } from "rxjs";


@Injectable()
export class SearchParamService {
    private route = inject(ActivatedRoute);
    private router = inject(Router);


    setSearchParam = (paramName: string, value: unknown) => {
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: { [paramName]: value },
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            }
        );
    }

    getNumberedSearchParam = (paramName: string, defaultValue?: number) => {
        return this.route.queryParamMap.pipe(
            map((params: ParamMap) => {
                const p = params.get(paramName)
                return (p ? parseInt(p) : defaultValue) || 0;
            }),
        )
    }
}