import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ArrayApiResponse, ButtonInfo, Ecosystem, Tag, TechDetail } from '../models/models';
import { TechService } from '../services/tech.service';
import { combineLatest, distinctUntilChanged, fromEvent, map, startWith, Subject, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-tech-overview',
  templateUrl: './tech-overview.component.html' ,
  styleUrls: ['./tech-overview.component.css'],
})
export class TechOverviewComponent implements OnInit, OnDestroy {
  @Input() filterValue: string = '';
  @Input() selectedEcosystems: Ecosystem[] = [];
  @Input() selectedTags: Tag[] = [];

  ALL_VALUES: string = 'ALL';

  activeTechs: TechDetail[] = [];
  ecosystems: Ecosystem[] = [];
  filter: string = '';
  filteredTechs: TechDetail[] = [];
  groupedButtonInfo: Record<string, ButtonInfo> = {};
  selectedEcosystem: string = this.ALL_VALUES;
  selectedTag: string = this.ALL_VALUES;
  tags: Tag[] = [];
  techs: TechDetail[] = [];
  id?: string | null = null;

  isMobileView: boolean = false;
  showAllTechs: boolean = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private techService: TechService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getCollabResourceButton();

    fromEvent(window, 'resize')
      .pipe(
        map(() => window.innerWidth),
        startWith(window.innerWidth),
        distinctUntilChanged()
      )
      .subscribe((width) => {
        this.isMobileView = width <= 768;
      });
  
    combineLatest([this.techService.getTechsDetails(), this.techService.getEcosystems(), this.techService.getTags()])
      .pipe(
        map(([techResponse, ecosystemResponse, tagResponse]) =>
        ({
          techs: mapResponse(techResponse),
          ecosystems: mapResponse(ecosystemResponse),
          tags: mapResponse(tagResponse),
        })),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(({ techs, ecosystems, tags }) => {
        this.techs = techs;
        this.ecosystems = ecosystems;
        this.tags = tags;
  
        this.selectedEcosystem = window.sessionStorage.getItem("ecoId") ?? this.ALL_VALUES;
        this.selectedTag = window.sessionStorage.getItem("tagId") ?? this.ALL_VALUES;
  
        this.getFilteredTechs();
    });
  
    this.route.params.subscribe((params) => {
      this.id = params['id'] ?? null;
    });
  
    this.getFilteredTechs();
  }

  handleSearchValue(value: string) {
    this.filter = value;
    this.getFilteredTechs();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setEcosystemFilter(ecosystemId: string) {
    window.sessionStorage.setItem("ecoId", ecosystemId);
    this.selectedEcosystem = ecosystemId;
  }

  setTagFilter(tagId: string) {
    window.sessionStorage.setItem("tagId", tagId);
    this.selectedTag = tagId;
  }

  get filteredTechsMobile(): TechDetail[] {
    const unfilteredTechs = this.showAllTechs ? this.techs : this.techs.filter((tech) => !tech.isArchived);
  
    return unfilteredTechs
      .filter(tech => tech.name.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()))
      .filter(tech => this.selectedEcosystem === this.ALL_VALUES || (tech.ecosystems ?? []).some(es => es.id === this.selectedEcosystem))
      .filter(tech => this.selectedTag === this.ALL_VALUES || (tech.tags ?? []).some(t => t.id === this.selectedTag))
      .sort((t1,t2) => t1.name.toLocaleLowerCase() < t2.name.toLocaleLowerCase()? -1 : 1);
  }

  getFilteredTechs(): void {
    const unfilteredTechs = this.showAllTechs ? this.techs : this.techs.filter((tech) => !tech.isArchived);
  
    this.filteredTechs = unfilteredTechs
      .filter(tech => tech.name.toLocaleLowerCase().includes(this.filter?.toLocaleLowerCase() ?? ''))
      .filter(tech => (
        this.selectedTags.length === 0 ||
        tech.tags?.some(t => this.selectedTags.map(st => st.id).includes(t.id))
      ))
      .filter(tech => 
        this.selectedEcosystems.length === 0 ||
        tech.ecosystems?.some(t => this.selectedEcosystems.map(se => se.id).includes(t.id))
      )
      .sort((t1, t2) => t1.name.toLocaleLowerCase() < t2.name.toLocaleLowerCase() ? -1 : 1);
  }

  async getCollabResourceButton() {
    const url = `${environment.collabBackendApiUrl}/api/resource/allButtoninfo`;
    const params = { url: 'tech' };
    const headers = {
      Authorization: `Bearer ${window.sessionStorage.getItem('JWT')}`
    };

    const observer = {
      next: (response: ButtonInfo[]) => {
        const grouped = response.reduce((prev, next) => {
          const id = next.resource?.split('/')[next.resource.split('/').length - 1] || '';
          return { ...prev, [id]: next };
        }, {} as Record<string, ButtonInfo>);
        this.groupedButtonInfo = grouped;
      },
      error: (error: any) => {
        console.error(error);
      }
    };

    this.http.get<Array<ButtonInfo>>(url, { params, headers }).subscribe(observer);
  }
}

const mapResponse = <TResponse>(response: ArrayApiResponse<TResponse>): TResponse[] => !response.error ? (response.data ? response.data : []) : [];