<div id="container mt-3">
  <div class="container card shadow-sm p-3">
    <div id="header" class="form-group position-relative">
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <label for="filter">Filter</label>
          <div>
            <p class="btn" (click)="toggleCollapse()" [title]="toggleCollapseStatus ? 'Expand all genres' : 'Collapse all genres'">
              <em class="bi" [ngClass]="{'bi-chevron-expand': toggleCollapseStatus, 'bi-chevron-contract': !toggleCollapseStatus}"></em>
            </p>
            <p class="btn" (click)="resetFilters()" title="Reset filter"><em class="bi bi-trash"></em></p>
          </div>
        </div>
        <hr class="row justify-content-center">
      </div>
      <div class="search-container">
        <em class="bi bi-search"></em>
        <input type="text" (input)="emitOutput()" [(ngModel)]="filter" name="filter" id="filter" placeholder="Search">
      </div>
    </div>
    <hr>
    <div class="genre">
      <app-collapsible buttonLabel="Current selected tags">
        <ng-container *ngFor="let ecosystem of allEcosystems">
        </ng-container>
        <div *ngIf="(currentEcosystems)?.length">
          <div *ngFor="let ecosystem of allEcosystems">
            <app-tag
              *ngIf="ecosystem.selected"
              [isSelected]="ecosystem.selected!"
              [name]="ecosystem.name"
              [backgroundColor]="ecosystem.backgroundColor!"  
              [textColor]="ecosystem.textColor!"
              (click)="changeButtonProperties(ecosystem)">
            </app-tag>
          </div>
          <hr class="w-50" *ngIf="(currentTags)?.length">
        </div>
        <app-tag
          *ngFor="let tag of currentTags" 
          [isSelected]="tag.selected!"
          [name]="tag.name"
          [backgroundColor]="tag.tagGenre?.backgroundColor!"
          [textColor]="tag.tagGenre?.textColor!"
          (click)="changeButtonProperties(tag)">
        </app-tag>
      </app-collapsible>
      <hr>
    </div>
    <div class="genre">
      <app-collapsible buttonLabel="Ecosystem">
        <app-tag 
          *ngFor="let ecosystem of allEcosystems" 
          [isSelected]="ecosystem.selected!"
          [name]="ecosystem.name"
          [backgroundColor]="ecosystem.backgroundColor!"
          [textColor]="ecosystem.textColor!" 
          (click)="changeButtonProperties(ecosystem)">
        </app-tag>
      </app-collapsible>
      <hr>
    </div>
    <div class="tag-genre-box" *ngFor="let genre of allGenres">
      <ng-container *ngIf="filterTagByGenre(genre.id)?.length">
        <app-collapsible buttonLabel="{{genre.name}}">
          <app-tag 
            *ngFor="let tag of filterTagByGenre(genre.id)" 
            [isSelected]="tag.selected!"
            [name]="tag.name"
            [backgroundColor]="tag.tagGenre?.backgroundColor!"
            [textColor]="tag.tagGenre?.textColor!"
            (click)="changeButtonProperties(tag)">
          </app-tag>
        </app-collapsible>
        <hr>
      </ng-container>
    </div>
    <div class="genre" *ngIf="(filterTagWithoutGenre())?.length">
      <app-collapsible buttonLabel="Tags without genres">
        <app-tag 
          *ngFor="let tag of filterTagWithoutGenre()" 
          [isSelected]="tag.selected!"
          [name]="tag.name"
          [backgroundColor]="tag.tagGenre?.backgroundColor!"
          [textColor]="tag.tagGenre?.textColor!"
          (click)="changeButtonProperties(tag)">
        </app-tag>
      </app-collapsible>
      <hr>
    </div>
  </div>
</div>