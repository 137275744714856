import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';
import { JwtProviderService } from '../jwt-provider/jwt-provider.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jwt-loader',
  templateUrl: './jwt-loader.component.html',
  styleUrls: ['./jwt-loader.component.scss'],
})
export class JwtLoaderComponent implements OnInit {
  @ContentChild(TemplateRef) template: any;
  $token: Observable<string>;

  constructor(private tokenProvider: JwtProviderService, private router: Router) {
    this.$token = tokenProvider.$token;
  }

  ngOnInit(): void {
    this.tokenProvider.addTokenListener();
    this.tokenProvider.sendReadyMessage();

    window.addEventListener('message', async ({data}: MessageEvent) => {
      if (data.messageType !== 'route') return;
      await this.router.navigate([data.payload.route]);
    });
  }
}
