export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const slugTranslations = [
  { from: '&', to: 'and' },
  { from: '.', to: 'dot' },
  { from: '#', to: 'sharp' },
  { from: '+', to: 'plus' },
]

export const sluggify = (name: string) => {
  //use slugTranslations to replace special characters with word equivalent
  const translatedSlug = slugTranslations.reduce(
    (specialSlug, { from, to }) => specialSlug.replace(new RegExp(`\\${from}`,"g"), to),
    name);
  
  //replace remaining special characters with '-'
  return translatedSlug.replace(/[^a-zA-Z0-9]{1}/g, '-').toLowerCase();

}