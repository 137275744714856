import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideToastr } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routing';
import { TokenInterceptor } from './app/core/interceptors/token.interceptor';
import { SearchParamService } from './app/services/searchParam.service';
import { TechService } from './app/services/tech.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    provideToastr(),
    TechService,
    SearchParamService,
    provideHttpClient(withInterceptorsFromDi())
  ],
}).catch(err => console.error(err));