import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { createPopper } from "@popperjs/core";

@Component({
  standalone: true,
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnDestroy, AfterViewInit {
  popperInstance: ReturnType<typeof createPopper> | null = null;
  showEvents = ['mouseover'];
  hideEvents = ['mouseout'];

  @Input() id?: string;

  @ViewChild("targetRef", { static: true })
  targetRef!: ElementRef<HTMLDivElement> | null;

  @ViewChild("tooltipRef", { static: true })
  tooltipRef!: ElementRef<HTMLDivElement>

  constructor() { }

  destroy() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
  }

  show = () => {
    const tooltip = this.tooltipRef?.nativeElement;
    const target = this.targetRef?.nativeElement;

    tooltip?.setAttribute('data-show', '');

    if (target && tooltip)
      this.popperInstance = createPopper(target, tooltip, {
        placement: 'bottom-start'
      });

  }

  hide = () => {
    const tooltip = this.tooltipRef?.nativeElement;
    tooltip?.removeAttribute('data-show');
    this.destroy();
  }
  ngAfterViewInit(): void {
    const target = this.targetRef?.nativeElement;

    this.showEvents.forEach(event => {
      target?.addEventListener(event, this.show);
    });

    this.hideEvents.forEach(event => {
      target?.addEventListener(event, this.hide);
    });
  }
  
  ngOnDestroy() {
    const target = this.targetRef?.nativeElement;

    this.showEvents.forEach(event => {
      target?.removeEventListener(event, this.show);
    });

    this.hideEvents.forEach(event => {
      target?.removeEventListener(event, this.hide);
    });
    this.destroy()
  }
}
