import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tag, TagGenre } from '../models/models';
import { TechService } from '../services/tech.service';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';


@Component({
    selector: 'app-tag-edit',
    templateUrl: './tag-edit.component.html',
    styleUrls: ['./tag-edit.component.css'],
    standalone: true,
    imports: [
    FormsModule,
    ReactiveFormsModule
],
})
export class TagEditComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private techService = inject(TechService);
  private formBuilder = inject(FormBuilder);

  title: string = 'Add Tag';
  id: string = '';
  tag?: Tag;
  tagGenres?: TagGenre[];
  tagForm: FormGroup;

  constructor() {
    this.tagForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      tagGenre: '',
    });
  }

  submit(): void {
    const updatedTag: Tag = {
      ...(this.tag ?? {}),
      name: this.tagForm.get('name')?.value,
    };
    if (this.id) {
      this.techService.putTag(this.id, updatedTag).subscribe({
        next: async (_) => {
          this.techService.putGenreOnTag(this.id, this.tagForm.get('tagGenre')?.value).subscribe();
          await this.router.navigate(['/tag']);
        },
        error: (err) => console.error(err),
      });
    } else {
      this.techService.postTag(updatedTag).subscribe({
        next: async (tagResponse) => {
          if (tagResponse.data) {
            this.techService
              .putGenreOnTag(tagResponse.data.id ?? '', this.tagForm.get('tagGenre')?.value)
              .subscribe();
          }
          await this.router.navigate(['/tag']);
        },
        error: (err) => console.error(err),
      });
    }
  }

  async back(): Promise<void> {
    await this.router.navigate(['/tag']);
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.techService.getTagGenres().subscribe({
        next: (tagGenres) => {
          if (tagGenres.data) this.tagGenres = tagGenres.data;
        },
      });
      if (this.id) {
        this.title = 'Edit Tag';
        this.techService.getTagWithDetailById(this.id).subscribe({
          next: (tag) => {
            if (tag.data) {
              this.tag = tag.data;
              this.tagForm.setValue({
                name: tag.data.name,
                tagGenre: tag.data.tagGenre?.id ?? ''
              });
            }
            if (tag.error) {
              return;
            }
          },
          error: (e) => {},
        });
      }
    });
  }
}
