<div class="container mt-3">
  <div class="container card shadow-sm p-3">
    <h3>{{ title }}</h3>
    <form [formGroup]="techForm" (submit)="submit()">
      <div class="form-group">
        <label for="name">Name</label><br />
        <input 
          type="text" 
          id="name" 
          name="name" 
          class="form-control" 
          formControlName="name" 
          required />
        <div *ngIf="techForm.get('name')?.invalid && (techForm.get('name')?.dirty || techForm.get('name')?.touched)" class="alert alert-danger">
          <div *ngIf="techForm.get('name')?.errors?.['required']">Name is required.</div>
          <div *ngIf="techForm.get('name')?.errors?.['minlength']">Name must be at least 4 characters long.</div>
          <div *ngIf="techForm.get('name')?.errors?.['forbiddenName']">Name cannot be Bob.</div>
        </div>
        
        <label for="url">URL</label><br />
        <input 
          type="text" 
          id="url" 
          name="url" 
          class="form-control" 
          formControlName="url" />
          <div *ngIf="techForm.get('url')?.invalid && (techForm.get('url')?.dirty || techForm.get('url')?.touched)" class="alert alert-danger">
            <div *ngIf="techForm.get('url')?.errors?.['required']">URL is required.</div>
            <div *ngIf="techForm.get('url')?.errors?.['pattern']">Don't be silly, enter a valid URL.</div>
          </div>
        
        <label for="description">Description</label><br />
        <textarea
          type="text"
          id="description"
          name="description"
          class="form-control"
          formControlName="description"
          rows="7">
        </textarea>
        <div *ngIf="techForm.get('description')?.invalid && (techForm.get('description')?.dirty || techForm.get('description')?.touched)" class="alert alert-danger">
          <div *ngIf="techForm.get('description')?.errors?.['required']">Description is required.</div>
          <div *ngIf="techForm.get('description')?.errors?.['minlength']">Description must be at least 10 characters long.</div>
        </div>
        
        <label for="imageUrl">Image</label><br />
        <div class="d-flex gap-1">
          <button
            type="button"
            class="btn btn-primary btn-sm d-flex align-items-center gap-1"
            (click)="uploadImage()">
            <span class="bi bi-cloud-upload"></span>
            Upload
          </button>
          <textarea
            type="text"
            id="imageUrl"
            name="imageUrl"
            class="form-control"
            formControlName="imageUrl"
            rows="1">
          </textarea>
        </div>
        <div *ngIf="techForm.errors?.['noFileOrUrl'] && (techForm.get('imageUrl')?.touched)" class="alert alert-danger">Please provide either an image URL or upload an image file.</div>

        <label for="tags" class="me-2">Tags</label>
        <span
          *ngFor="let selectedTag of selectedTags"
          class="badge bg-secondary my-2 me-2 set-pointer"
          (click)="removeSelectedTag(selectedTag?.id ?? '')">
          x {{ selectedTag.name }}
        </span>

        <select id="tags" class="form-select" name="tags" #id (change)="selectTag(id.value)">
          <option value="-1">Select a tag</option>
          <option *ngFor="let tag of allTags" [value]="tag.id">{{ tag.name }}</option>
        </select>

        <label for="ecosystem">Ecosystem</label><br />
        <select class="form-select" name="ecosystem" formControlName="ecosystem" aria-label="Select ecosystem">
          <option *ngFor="let ecosystem of ecosystems" [value]="ecosystem.id">
            {{ ecosystem.name }}
          </option>
        </select>
        <br />

        <div class="w-100">
          <button
            class="btn btn-outline-primary mx-1 btn-sm"
            type="submit"
            [disabled]="techForm.invalid">
            <span class="bi bi-save"></span>
            Save
          </button>
          <button
            class="btn btn-outline-secondary btn-sm"
            type="button"
            (click)="back()"
            [disabled]="isSubmitting">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>