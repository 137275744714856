import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Tag, TagGenre } from '../models/models';

import { TechService } from '../services/tech.service';

@Component({
  selector: 'app-tag-overview',
  templateUrl: './tag-overview.component.html',
  styleUrls: ['./tag-overview.component.css'],
})
export class TagOverviewComponent implements OnInit {
  tags?: Tag[];
  tagGenres?: TagGenre[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private techService: TechService,
  ) {}

  ngOnInit(): void {
    this.techService.getTagsWithDetail().subscribe({
      next: (tags) => {
        if (tags.data) this.tags = tags.data;
      },
    });
    this.techService.getTagGenres().subscribe({
      next: (tagGenres) => {
        if (tagGenres.data) { 
          this.tagGenres = tagGenres.data;
          this.tagGenres.push({id: undefined, name: "None", backgroundColor: "#6C757D", textColor: "#FFFFFF"})
        }
      },
    });
  }

  async onClickedTagEdit(tag?: Tag) {
    await this.router.navigate([tag?.id ? `edit/${tag.id}` : 'add'], { relativeTo: this.route });
  }

  async onClickedTagGenreEdit(tagGenre?: TagGenre) {
    await this.router.navigate([tagGenre?.id  ? `/tagGenre/edit/${tagGenre.id}` : '/tagGenre/add']);
  }

  getTagsOfGenre(tagGenre: TagGenre): Tag[] {
    if (tagGenre?.id !== undefined) {
      return this.tags?.filter((t) => t.tagGenre?.id === tagGenre.id) ?? new Array<Tag>();
    } else {
      return this.tags?.filter((t) => t.tagGenre?.id === undefined) ?? new Array<Tag>();
    }
  }
}
