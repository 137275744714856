<div class="container mt-3">
  <div class="container card shadow-sm p-3">
    <h3>{{ addOrEdit }}</h3>
    <form [formGroup]="genreForm" (ngSubmit)="submit()" novalidate>
      <div class="form-group">
        <div class="row mb-3">
          <label for="name" class="form-label">Name</label>
          <div class="col">
            <input class="form-control placeholder-color" 
              type="text" 
              name="name" 
              formControlName="name" 
              [style.background-color]="backgroundColor" 
              [style.color]="textColor" 
              placeholder="Type genre name here."
              autocomplete="off"
              autofocus
              required/>
            <div *ngIf="genreForm.get('name')?.invalid && (genreForm.get('name')?.dirty || genreForm.get('name')?.touched)" class="alert alert-danger">
              <div *ngIf="genreForm.get('name')?.errors?.['required']">Name is required.</div>
              <div *ngIf="genreForm.get('name')?.errors?.['minlength']">Name must be at least 4 characters long.</div>
            </div>
          </div>
          <div class="col">
            <p>
              Contrast Ratio: {{ contrastRatio | number: '1.2-2' }}&#47;21
              <span *ngIf="contrastRatio >= contrastRatioThreshhold" class="text-success">
                <em class="bi bi-check-circle-fill"></em> Good
              </span>
              <span *ngIf="contrastRatio < contrastRatioThreshhold" class="text-danger">
                <em class="bi bi-exclamation-triangle-fill"></em> Bad
              </span>
            </p>
          </div>
          <div class="col"></div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="background_color" class="form-label">Background color</label>
            <input id="background_color" class="form-control colorpicker" 
              type="color" 
              name="background_color" 
              [value]="backgroundColor"
              formControlName="background_color" 
              #backgroundColorInput (input)="updateBackgroundColor(backgroundColorInput.value)" />
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="text_color" class="form-label">Text color</label>
            <input id="text_color" class="form-control colorpicker" 
              type="color" 
              name="text_color" 
              [value]="textColor"
              formControlName="text_color" 
              #textColorInput (input)="updateTextColor(textColorInput.value)" />
          </div>
          <div class="col"></div>
          <div class="col"></div>
        </div>

        <div class="row">
          <div class="col">
            <button 
              class="btn btn-outline-primary mx-1 btn-sm" 
              type="submit" 
              [disabled]="genreForm.invalid">
              <span class="bi bi-save"></span> Save
            </button>
            <button class="btn btn-outline-secondary btn-sm" type="button" (click)="back()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
