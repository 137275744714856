<div class="container">
    <div class="count-section">
        <h6>
            <span class="count-section-spacing">
                {{ buttonInfo?.commentCount ?? 0 }}
            </span>
        </h6>
        <em class="bi bi-chat-left icon-styling"></em>
    </div>
    <div class="count-section">
        <h6>
            <div class="count-section-spacing">
                {{ buttonInfo?.reactionCount ?? 0 }}
            </div>
        </h6>
        <em class="bi bi-heart icon-styling"></em>
    </div>
</div>
