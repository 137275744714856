import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonInfo, TechDetail } from '../models/models';
import { TechService } from '../services/tech.service';

@Component({
  selector: 'overview-desktop-card',
  templateUrl: './overview-desktop-card.component.html',
  styleUrls: ['./overview-desktop-card.component.css'],
})
export class overviewDesktopCardComponent implements OnInit, OnDestroy {
  @Input() tech: TechDetail | null = null;
  @Input() buttonInfo?: ButtonInfo;

  groupedButtonInfo: Record<string, ButtonInfo> = {};

  constructor(
    private techService: TechService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async onClickedView(tech: TechDetail| null) {
    await this.router.navigate([tech?.id], { relativeTo: this.route });
  }

  async onClickedEditTag(tag?: TechDetail | null) {
    await this.router.navigate([`/tag/edit/${tag?.id ?? ''}`] );
  }
}