import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, FormControl, Validators  } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TagGenre } from '../models/models';

import { ColorUtilityService } from '../services/color-utility/color-utility.service';
import { TechService } from '../services/tech.service';

@Component({
  selector: 'app-tag-overview',
  templateUrl: './tag-genre-edit.component.html',
  styleUrls: ['./tag-genre-edit.component.css'],
})
export class TagGenreEditComponent implements OnInit {
  id: string = '';
  tagGenre?: TagGenre;

  genreForm: FormGroup;
  
  backgroundColor: string = '#6C757D'; // default background color gray
  textColor: string = '#FFFFFF'; // default text color white
  contrastRatio: number = 0;
  contrastRatioThreshhold: number = 4.5;
  addOrEdit: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private techService: TechService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2, 
    private colorUtilityService: ColorUtilityService,
  ) {
    this.genreForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      background_color: '',
      text_color: '',
    });
  }
  
  updateBackgroundColor(color: string) {
    this.backgroundColor = color;
  
    const newTextColor = this.colorUtilityService.chooseBestContrastColor(this.backgroundColor);
    this.textColor = newTextColor;
    this.updatePlaceholderColor(newTextColor); 
  
    this.calculateContrastRatio();
  }

  updateTextColor(color: string) {
    this.textColor = color;
    this.calculateContrastRatio();

  }

  updatePlaceholderColor(color: string) {
    this.renderer.setStyle(document.documentElement, '--placeholder-color', color);
  }

  calculateContrastRatio() {
    const contrastRatio = this.colorUtilityService.calculateContrastRatio(this.backgroundColor, this.textColor);
    this.contrastRatio = contrastRatio;
  }
  
  ngOnInit(): void {
    this.genreForm.setValue({
      name: [''],
      background_color: [this.backgroundColor],
      text_color: [this.textColor],
    });

    this.calculateContrastRatio();
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.techService.getTagGenreById(this.id).subscribe({
          next: (genre) => {
            if (genre.data) {
              this.tagGenre = genre.data;
              this.genreForm.setValue({
                name: genre.data.name,
                background_color: genre.data.backgroundColor,
                text_color: genre.data.textColor,
              });
              this.backgroundColor = genre.data.backgroundColor;
              this.textColor = genre.data.textColor;
            }
            if (genre.error) {
              return;
            }
          },
          error: (e) => {},
        });
      }
    });

    if(this.id) {
      this.addOrEdit = "Edit Tag Genre";
    } else {
      this.addOrEdit = "Add Tag Genre";
    }
  }

  submit(): void {
    if (this.genreForm.invalid) {
      return;
    }

    const updatedTagGenre: TagGenre = {
      ...(this.tagGenre ?? {}),
      name: this.genreForm.get('name')?.value,
      backgroundColor: this.backgroundColor,
      textColor: this.textColor,
    };
    
    if (this.id) {
      this.techService.putTagGenre(this.id, updatedTagGenre).subscribe({
        next: async (_) => await this.router.navigate(['/tag']),
        error: (err) => console.error(err),
      });
    } else {
      this.techService.postTagGenre(updatedTagGenre).subscribe({
        next: async (_) => await this.router.navigate(['/tag']),
        error: (err) => console.error(err),
      });
    }
  }

  async back(): Promise<void> {
    await this.router.navigate(['/tag']);
  }
}
