import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})
export class TagComponent implements OnInit {
  @Input() isSelected: boolean= false;
  @Input() name: string = '';
  @Input() backgroundColor: string = '#6C757D';
  @Input() textColor: string = '#FFFFFF';

  constructor() {
    // Empty constructor for structural purposes
  }

  ngOnInit(): void {
    // Empty ngOnInit for structural purposes
  }
}