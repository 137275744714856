import { Component, ViewChildren, QueryList, EventEmitter, OnInit, Output, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CollapsibleComponent } from '../collapsible/collapsible.component';
import { ToastrService } from 'ngx-toastr';

import { Ecosystem, Tag, TagGenre, TechDetail } from '../models/models';
import { TechService } from '../services/tech.service';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.css'],
})
export class TagSelectorComponent implements OnInit {
  @Output() tagFilters = new EventEmitter<Tag[]>();
  @Output() ecosystemFilters = new EventEmitter<Ecosystem[]>();
  @Output() searchFilter = new EventEmitter<string>();
  @ViewChildren(CollapsibleComponent) collapsibles!: QueryList<CollapsibleComponent>;

  DEFAULT_BACKGROUNDCOLOR: string = '#6C757D';
  DEFAULT_TEXTCOLOR: string = '#FFFFFF';
  SELECTED_OPACITY: number = 50;
  UNSELECTED_OPACITY: number = 100;

  allTags: Tag[] = [];
  allGenres: TagGenre[] = [];
  allEcosystems: Ecosystem[] = [];
  currentTags: Tag[] = [];
  currentEcosystems: Ecosystem[] = [];
  currentTech?: TechDetail;
  currentTechId?: string | null;
  filter: string = '';
  toggleCollapseStatus: boolean = false;

  constructor(private techService:TechService,
              private route:ActivatedRoute,
              private toastr:ToastrService) { }

  ngOnInit(): void {
    this.loadData();
  }

  toast(message: string, title: string){
    this.toastr.error(message, title, {timeOut: 5000,});
  }

  loadData(): void {
    this.loadTechData();
    this.loadTagsWithDetail();
    this.loadTagGenres();
    this.loadEcosystems();
  }
  
  private loadTechData(): void {
    this.route.paramMap.subscribe(params => {
      this.currentTechId = params.get('id');
      if (this.currentTechId !== undefined && this.currentTechId !== null) {
        this.techService.getTechDetailById(this.currentTechId).subscribe(tech => {
          if (tech.data) {
            this.currentTech = tech.data;
            if (tech.data.tags) {
              this.currentTags = tech.data.tags;
            }
          }
        });
      }
    });
  }
  
  loadTagsWithDetail(): void {
    this.techService.getTagsWithDetail().subscribe({
      next: (tags) => this.handleDataLoad(tags.data, this.allTags, "Tag")
    });
  }
  
  loadTagGenres(): void {
    this.techService.getTagGenres().subscribe({
      next: (genres) => this.handleDataLoad(genres.data, this.allGenres, "TagGenre")
    });
  }

  loadEcosystems(): void {
    this.techService.getEcosystems().subscribe({
      next: (ecosystems) => {
        this.handleDataLoad(
          ecosystems.data?.map(ecosystem => ({
            ...ecosystem,
            backgroundColor: ecosystem.backgroundColor || this.DEFAULT_BACKGROUNDCOLOR,
            textColor: ecosystem.textColor || this.DEFAULT_TEXTCOLOR
          })),
          this.allEcosystems,
          "Ecosystem"
        );
      }
    });
  }
  
  handleDataLoad(data: any, targetArray: any[], dataType: string): void {
    if (data) {
      targetArray.splice(0, targetArray.length, ...data); // Clear and replace the array elements
    } else {
      this.toast(`Could not load ${dataType} data.`, "Error loading data.");
    }
  }

  emitOutput() {
    this.tagFilters.emit(this.currentTags);
    this.ecosystemFilters.emit(this.currentEcosystems);
    this.searchFilter.emit(this.filter);
  }

  isTag(item: Tag | Ecosystem): boolean {
    if (item && 'tagGenre' in item) {
      return true;
    } else if (item && 'backgroundColor' in item) {
      return false
    } else {
      this.toast("Invalid datatype.", "Something went wrong.");
      return false
    }
  }

  toggleCollapse() {
    this.toggleCollapseStatus = !this.toggleCollapseStatus;
    if (!this.toggleCollapseStatus) {
      this.expandAll();
    } else {
      this.collapseAll();
    }
  }

  collapseAll() {
    this.collapsibles.forEach(collapsible => collapsible.collapse());
  }

  expandAll() {
    this.collapsibles.forEach(collapsible => collapsible.expand());
  }

  resetFilters() {
    this.filter = "";

    this.expandAll();
    this.toggleCollapseStatus = false;

    this.EmptyAllCurrentLists();

    this.emitOutput();
  }

  filterTagByGenre(genreId: string | undefined): Tag[] | undefined {
    return this.allTags?.filter(tag => tag.tagGenre?.id === genreId);
  }

  filterTagWithoutGenre(): Tag[] | undefined {
    return this.allTags?.filter(tag => tag.tagGenre=== null);
  }

  EmptyAllCurrentLists() {
    this.emptyList(this.currentEcosystems);
    this.emptyList(this.currentTags);
  }

  emptyList(items: (Ecosystem | Tag)[]) {
    items.forEach(item => {
      this.changeButtonProperties(item);
    });
  }

  changeButtonProperties(item: Tag | Ecosystem) {
    item.selected = !item.selected;
    this.changeOpacity(item);
    this.updateCurrentLists(item);
  }

  changeOpacity(item: Tag | Ecosystem) {
    let isTag = this.isTag(item);
    if (isTag) {
      let tag = item as Tag;
      tag.tagGenre!.backgroundColor = this.changeBackgroundColorAlpha(
        tag.tagGenre?.backgroundColor!, 
        tag.selected ? this.SELECTED_OPACITY : this.UNSELECTED_OPACITY
      );
      this.updateItem(tag, this.allTags);
    }
    if (!isTag) {
      let ecosystem = item as Ecosystem;
      ecosystem.backgroundColor = this.changeBackgroundColorAlpha(
        this.DEFAULT_BACKGROUNDCOLOR, 
        ecosystem.selected ? this.SELECTED_OPACITY : this.UNSELECTED_OPACITY
      );
      this.updateItem(ecosystem, this.allEcosystems);
    }
  }

  changeBackgroundColorAlpha(color: string, value: number): string {
    return color.substring(0, 7) + this.percentToHex(value);
  }

  percentToHex(percentValue: number): string {
    if (percentValue === 100) return "";

    let hexString: string = Math.round(percentValue * 255 / 100).toString(16);
    if (hexString.length < 2) {
        hexString = "0" + hexString;
    }
    if (hexString.length > 2 && hexString.length < 0) {
      this.toast("invalid hexString length.", "Something went wrong");
    }
    return hexString;
  }

  updateItem(updatedItem: Tag | Ecosystem, collection: (Tag | Ecosystem)[]) {
    const index = collection.findIndex(x => x.id === updatedItem.id);
    if (index === -1) {
        this.toast("Current item not found in item list.", "currentItem");
        return;
    }
    collection[index] = updatedItem;
  }

  updateCurrentLists(item: Tag | Ecosystem) {
    let isTag = this.isTag(item);
    if (item.selected) {
      if (isTag) this.addTagToCurrentTags(item as Tag);
      if (!isTag) this.addEcosystemToCurrentEcosystems(item as Ecosystem);
    } else {
      if (isTag) this.removeTagFromCurrentTags(item as Tag);
      if (!isTag) this.removeEcosystemFromCurrentEcosystems(item as Ecosystem);
    }
  }

  addTagToCurrentTags(tag: Tag): void {
    this.addItem<Tag>(
      tag, 
      this.allTags, 
      this.currentTags, 
    );
  }
    
  addEcosystemToCurrentEcosystems(ecosystem: Ecosystem): void {
    this.addItem<Ecosystem>(
      ecosystem, 
      this.allEcosystems, 
      this.currentEcosystems, 
    );
  }

  addItem<T extends { id?: string, name?: string }>(
    item: T,
    allItems: T[],
    currentItems: T[],
  ): void {
    if (!item.id) {
      this.toast("Item ID is missing", "item.id");
      return;
    }
    
    if (!allItems) {
      this.toast("All items array is missing", "allItems");
      return;
    }
  
    const currentItem = allItems.find(x => x.id === item.id);
    if (!currentItem) {
      this.toast("Current item not found in all items", "currentItem");
      return;
    }
  
    currentItems.push(currentItem);
    this.emitOutput();
  }

  removeTagFromCurrentTags(tag: Tag): void {
    this.removeItem(
      tag,
      this.currentTags,
    );
  }

  removeEcosystemFromCurrentEcosystems(ecosystem: Ecosystem): void {
    this.removeItem(
      ecosystem,
      this.currentEcosystems,
    );
  }
  
  removeItem<T extends { id?: string, name?: string }>(
    item: T,
    currentItems: T[],
  ): void {
    if (item.id === undefined) {
      this.toast("Item ID is missing", "removeItem");
      return;
    }

    const currentItemIndex = currentItems.findIndex(x => x.id === item.id);

    if (currentItemIndex === -1) {
      this.toast("Item not found in current items", "removeItem");
      return;
    }

    currentItems.splice(currentItemIndex, 1);
    this.emitOutput();
  }
}