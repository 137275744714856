import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TechDetailComponent } from './tech-detail/tech-detail.component';
import { TechOverviewComponent } from './tech-overview/tech-overview.component';
import { TechEditComponent } from './tech-edit/tech-edit.component';
import {TagEditComponent} from "./tag-edit/tag-edit.component";
import { TagOverviewComponent } from './tag-overview/tag-overview.component';
import { TagGenreEditComponent } from './tag-genre-edit/tag-genre-edit.component';
import { TagSelectorComponent } from "./tag-selector/tag-selector.component";

enum Path {
  Tech = 'tech',
  Tag = 'tag',
  TagGenre = 'tagGenre'
}

const routes: Routes = [
  { path: '', redirectTo: '/tech', pathMatch: 'full' },
  {
    path: `${Path.Tag}`,
    component: TagOverviewComponent,
  },
  {
    path: `${Path.Tag}/add`,
    component: TagEditComponent,
  },
  {
    path: `${Path.Tag}/edit/:id`,
    component: TagEditComponent,
  },
  {
    path: `${Path.Tech}/tagoverview/:id`,
    component: TagSelectorComponent,
  },
  {
    path: Path.Tech,
    component: TechOverviewComponent,
  },
  {
    path: `${Path.Tech}/add`,
    component: TechEditComponent,
  },
  {
    path: `${Path.Tech}/edit/:id`,
    component: TechEditComponent,
  },
  {
    path: `${Path.Tech}/:id`,
    component: TechDetailComponent,
  },
  {
    path: `${Path.TagGenre}/add`,
    component: TagGenreEditComponent,
  },
  {
    path: `${Path.TagGenre}/edit/:id`,
    component: TagGenreEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
