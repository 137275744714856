import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ecosystem, Tag, TechDetail } from '../models/models';
import { TechService } from '../services/tech.service';
import { FormBuilder, FormGroup, Validators, AbstractControl } from "@angular/forms";
import { forkJoin, Observable} from "rxjs";

@Component({
  selector: 'app-tech-edit',
  templateUrl: './tech-edit.component.html',
  styleUrls: ['./tech-edit.component.css']
})
export class TechEditComponent implements OnInit {
  title: string = "Add Tech";
  tech!: TechDetail;
  tags?: Tag[];
  ecosystems?: Ecosystem[];
  selectedEcosystems?: Ecosystem;
  selectedTags: Tag[] = [];
  id?: string;
  isSubmitting: boolean = false;
  techForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private techService: TechService,
    private formBuilder: FormBuilder
  ) {
    this.techForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
      url: ['', [Validators.required, Validators.pattern('^https?://.+')]],
      description: ['', [Validators.required, Validators.minLength(10)]],
      ecosystem: [''],
      imageUrl: ['', [Validators.pattern('^https?://.+')]],
    });
  }

  submit(): void {
    this.isSubmitting = true;
    const updatedTech = {
      ...(this.tech?? {}),
      name: this.techForm.get("name")?.value,
      description: this.techForm.get("description")?.value,
      imageUrl: this.techForm.get("imageUrl")?.value,
      url: this.techForm.get("url")?.value
    }
    if (this.id) {
      this.techService.putTech(this.tech?.id ?? '-1', updatedTech).subscribe({
        next: async (_) => {
          const tagChanges =  this.updateTags(this.id?? '');
          const ecoChanges =  this.updateEcoSystem(this.id?? '', this.techForm.get("ecosystem")?.value);

          const changes = [...tagChanges, ...ecoChanges];
          if(changes.length === 0){
            this.navigateToPreviousPage();
          }
          let changeSubscription = forkJoin(changes).subscribe({
            next: x => {},
            error: err => {
              console.log( err);
              changeSubscription.unsubscribe();
              this.navigateToPreviousPage()

            },
            complete: () => {
              changeSubscription.unsubscribe();
              this.navigateToPreviousPage()
            }
          })
        },
        error: err => console.error(err)
      });
    }
  else {
      this.techService.postTech(updatedTech).subscribe({
        next: async (techResponse) => {
          if (techResponse.data) {
            this.updateTags(techResponse.data.id ?? '');
            this.updateEcoSystem(techResponse.data.id ?? '', this.techForm.get("ecosystem")?.value)
          }
          this.navigateToPreviousPage();
        },
        error: err => console.error(err)
      })
    }

  }
  async navigateToPreviousPage(): Promise<void> {
    await this.router.navigate(['..']);
  }
  selectTag(tagId: string): void {
    if(!this.tags || tagId === '-1') return;
    const foundTag: Tag = this.tags.find(tag => tag.id === tagId)?? {name: '', id: tagId};
    this.selectedTags = [...(this.selectedTags?? []), foundTag ];
  }

  removeSelectedTag(tagId: string): void {
    this.selectedTags = this.selectedTags.filter(({id}) => id !== tagId);
  }


  updateTags(techId: string) {
    const tagsToUpdate = this.selectedTags.map(({id}) => id).filter((x): x is string => !!x);
    const currentTags = this.tech?.tags?.map(({id}) => id).filter((x): x is string => !!x) ?? [];
    const newTags = tagsToUpdate.filter((id) => !currentTags.includes(id))
      .map((id) => this.techService.putTagsOnTech(techId, id));
    const removeTags = currentTags.filter(id => !tagsToUpdate.includes(id))
      .map(id => this.techService.deleteTagFromTech(techId, id));
    return [...newTags, ...removeTags];
  }
  
  updateEcoSystem(techId: string, ecosystemId: string) {
    let result: Observable<Object>[] = [];
    if (!this.tech?.ecosystems || this.tech.ecosystems.length === 0) {
      result.push(this.techService.putEcosystemOnTech(techId, ecosystemId));
    } else {
      const oldEcosystemId = this.tech.ecosystems[0]?.id;
      if (oldEcosystemId !== undefined && oldEcosystemId !== ecosystemId) {
        result = [
          this.techService.deleteEcosystemFromTech(techId, oldEcosystemId),
          this.techService.putEcosystemOnTech(techId, ecosystemId)
        ];
      }
    }
    return result;
  }  

  async back(): Promise<void> {
    await this.router.navigate([".."]);
  }

  uploadImage() {
    const openFileUploadMessage = {
      messageType: 'openFileUpload',
      payload: {
        appName: 'tech',
      },
    };

    window.parent.postMessage(openFileUploadMessage, '*');
  }

  get allTags(): Tag[] {
    const selectedTagIds =  this.selectedTags.map(({id}) => id);
    return this.tags?.filter(({id}) => !selectedTagIds.includes(id))?? [];
  }

  ngOnInit(): void {
    const handler = ({data}: MessageEvent) => {
      if(data.messageType === 'fileUploaded') {
        this.techForm.patchValue({
          imageUrl: data.payload.url
        });
      }
    }
    window.addEventListener('message', handler);

    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.techService.getTags().subscribe({next: (tags) => {
          if(tags.data) this.tags = tags.data;
        }})
      this.techService.getEcosystems().subscribe({next: (ecosystems) => {
          if(ecosystems.data) this.ecosystems = ecosystems.data;
        }})
      if (this.id) {
        this.title = "Edit Tech"
        this.techService.getTechDetailById(this.id).subscribe({
          next: (tech) => {
            if (tech.data) {
              this.tech = tech.data;
              this.selectedTags = tech.data.tags?? [];
              this.techForm.patchValue({
                name: tech.data.name,
                description: tech.data.description,
                imageUrl: tech.data.imageUrl,
                url: tech.data.url,
                ecosystem: tech.data.ecosystems?.[0]?.id ?? ''
              });
            }
            if (tech.error) {
              return;
            }
          },
          error: (e) => {
            console.error(e);
          },
        });
      }
    });
  }
}
