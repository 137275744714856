<div>
    <div class="non-selectable d-flex gap-2" (click)="toggleCollapse()" aria-expanded="false"  [title]="isCollapsed ? 'Expand ' + buttonLabel : 'Collapse ' + buttonLabel">
      <span class="bi non-selectable" [ngClass]="isCollapsed ? 'bi-chevron-right' : 'bi-chevron-down'"></span> 
      {{ buttonLabel }}
       <ng-content select="[label]"> </ng-content>
      
    </div>
    <div class="multi-collapse" [ngClass]="{'collapse': isCollapsed}">
      <ng-content></ng-content>
    </div>
  </div>
  