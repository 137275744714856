import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { SearchParamService } from '../services/searchParam.service';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_BUTTONS = 4;

@Component({
  standalone: true,
  imports:[MatButtonModule, CommonModule],
  selector: 'app-pagination-buttons',
  templateUrl: './pagination-buttons.component.html',
  styleUrls: ['./pagination-buttons.component.css']
})
export class PaginationButtonsComponent implements OnInit, OnChanges, OnDestroy {
  private searchParamService = inject(SearchParamService);

  @Input() searchParamName: string = 'page';
  @Input() totalElements?: number = 0;
  @Input() elementsPerPage: number = DEFAULT_PAGE_SIZE;
  @Input() numberOfInBetweenButtons: number = DEFAULT_PAGE_BUTTONS;

  pageNumberSub: Subscription | undefined;
  pageNumber: number = DEFAULT_PAGE_NUMBER;

  ngOnInit(): void {
    this.pageNumberSub = this.searchParamService.getNumberedSearchParam(this.searchParamName, DEFAULT_PAGE_NUMBER)
      .subscribe(pageNumber => this.pageNumber = pageNumber);
  }

  ngOnChanges(): void {
    this.goToFirstPage()
  }
  ngOnDestroy(): void {
    this.pageNumberSub?.unsubscribe();
  }

  get amountOfPages() {
    return Math.ceil(this.totalElements! / this.elementsPerPage)
  }

  get isFirstPage() {
    return this.pageNumber === 1
  }

  goToFirstPage = () => {
    this.goToPage(1)
  }

  goToLastPage = () => {
    this.goToPage(this.amountOfPages)
  }

  goToPage = (page: number) => {
    this.searchParamService.setSearchParam(this.searchParamName, page)
  }

  get isLastPage() {
    return this.pageNumber * this.elementsPerPage > this.totalElements!
  }
  get inBetweenPages() {
    const halfRange = Math.floor(this.numberOfInBetweenButtons / 2);

    let startValue = this.pageNumber - halfRange;
    let endValue = this.pageNumber + halfRange;

    if (startValue < 1) {
      startValue = 1;
      endValue = Math.min(this.numberOfInBetweenButtons + 1, this.amountOfPages);
    }

    // Adjust if endValue goes beyond the last page
    if (endValue > this.amountOfPages) {
      endValue = this.amountOfPages;
      startValue = Math.max(1, endValue - this.numberOfInBetweenButtons);
    }


    return Array.from({ length: endValue - startValue + 1 }, (_, i) => startValue + i);

  }
}
