import { Observable, take } from "rxjs";

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from '../../environments/environment';
import {
  ApiResponse,
  ArrayApiResponse,
  Tech,
  Tag,
  TagGenre,
  TechDetail,
  Ecosystem,
} from '../models/models';

@Injectable()
export class TechService {
  constructor(private http: HttpClient) {}

  //Get multiples

  getTechs(): Observable<ArrayApiResponse<Tech>> {
    return this.http.get<ArrayApiResponse<Tech>>(`${url}/techs`);
  }

  getEcosystems(): Observable<ArrayApiResponse<Ecosystem>> {
    return this.http.get<ArrayApiResponse<Ecosystem>>(`${url}/ecosystems`);
  }

  getTags(): Observable<ArrayApiResponse<Tag>> {
    return this.http.get<ArrayApiResponse<Tag>>(`${url}/tags`);
  }

  getTagsWithDetail(): Observable<ArrayApiResponse<Tag>> {
    return this.http.get<ArrayApiResponse<Tag>>(`${url}/tags/detail`);
  }

  getTagGenres(): Observable<ArrayApiResponse<TagGenre>> {
    return this.http.get<ArrayApiResponse<TagGenre>>(`${url}/tagGenres`);
  }

  getTechsDetails(): Observable<ArrayApiResponse<TechDetail>> {
    return this.http.get<ArrayApiResponse<TechDetail>>(`${url}/techs/detail`);
  }

  //Get singles

  getTechById(id: string): Observable<ApiResponse<Tech>> {
    return this.http.get<ApiResponse<Tech>>(`${url}/tech/` + id);
  }

  getTechDetailById(id: string): Observable<ApiResponse<TechDetail>> {
    return this.http.get<ApiResponse<TechDetail>>(`${url}/tech/detail/` + id);
  }

  getTagById(id: string): Observable<ApiResponse<Tag>> {
    return this.http.get<ApiResponse<Tag>>(`${url}/tag/` + id);
  }

  getTagWithDetailById(id: string): Observable<ApiResponse<Tag>> {
    return this.http.get<ApiResponse<Tag>>(`${url}/tag/detail/` + id);
  }

  getEcosystemById(id: string): Observable<ApiResponse<Ecosystem>> {
    return this.http.get<ApiResponse<Ecosystem>>(`${url}/ecosystem/` + id);
  }

  getTagGenreById(id: string): Observable<ApiResponse<TagGenre>> {
    return this.http.get<ApiResponse<TagGenre>>(`${url}/tagGenre/` + id);
  }

  //Post

  postTech(tech: Tech): Observable<ApiResponse<Tech>> {
    return this.http.post<ApiResponse<Tech>>(`${url}/tech`, tech);
  }

  postEcosystem(ecoSystem: Ecosystem): Observable<ApiResponse<Ecosystem>> {
    return this.http.post<ApiResponse<Ecosystem>>(
      `${url}/ecosystem`,
      ecoSystem
    );
  }

  postTag(tag: Pick<Tag, "name">): Observable<ApiResponse<Tag>> {
    return this.http.post<ApiResponse<Tag>>(`${url}/tag`, tag);
  }

  postTagGenre(tagGenre: Omit<TagGenre, "id">): Observable<ApiResponse<TagGenre>> {
    return this.http.post<ApiResponse<TagGenre>>(`${url}/tagGenre`, tagGenre);
  }

  //Put
  putTech(id: string, tech: Tech): Observable<ApiResponse<Tech>> {
    return this.http.put<ApiResponse<Tech>>(`${url}/tech/${id}`, tech);
  }

  putEcosystem(
    id: string,
    ecoSystem: Ecosystem
  ): Observable<ApiResponse<Ecosystem>> {
    return this.http.put<ApiResponse<Ecosystem>>('/ecosystem/' + id, ecoSystem);
  }

  putTag(id: string, tag: Tag): Observable<ApiResponse<Tag>> {
    return this.http.put<ApiResponse<Tag>>(`${url}/tag/` + id, tag);
  }

  putTagGenre(id: string, tagGenre: TagGenre): Observable<ApiResponse<TagGenre>> {
    return this.http.put<ApiResponse<TagGenre>>(`${url}/tagGenre/` + id, tagGenre);
  }

  putEcosystemOnTech(techid: string, ecosystemId: string) {
    return this.http.put(
      `${url}/tech/` + techid + '/ecosystems/' + ecosystemId,
      null
    ).pipe(take(1));
  }

  putTagsOnTech(techid: string, tagId: string) {
    return this.http.put(`${url}/tech/` + techid + '/tags/' + tagId, null).pipe(take(1));
  }

  putGenreOnTag(tagId: string, genreId: string) {
    return this.http.put(`${url}/tag/` + tagId + '/tagGenre/' + genreId, null);
  }

  // Delete

  deleteTech(id: string) {
    return this.http.delete(`${url}/tech/${id}`);
  }

  deleteEcosystemFromTech(id: string, ecosystemId: string) {
    return this.http.delete(`${url}/tech/${id}/ecosystems/${ecosystemId}`).pipe(take(1));
  }

  deleteTagFromTech(techid: string, tagId: string) {
    return this.http.delete(`${url}/tech/${techid}/tags/${tagId}`).pipe(take(1));
  }

  deleteEcosystem(id: string) {
    return this.http.delete(`${url}/ecosystem/` + id);
  }

  deleteTag(id: string) {
    return this.http.delete(`${url}/tag/` + id);
  }

  // Archive
  archiveTech(id: string) {
    return this.http.put(`${url}/tech/${id}/archive`, null).pipe(take(1));
  }
}
