
import { HttpClient } from '@angular/common/http';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, distinctUntilChanged, fromEvent, map, startWith, Subject, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { InboxComponent } from '../inbox/inbox.component';
import { ArrayApiResponse, ButtonInfo, Ecosystem, Tag, TechDetail } from '../models/models';
import { PaginationButtonsComponent } from '../pagination-buttons/pagination-buttons.component';
import { SearchParamService } from '../services/searchParam.service';
import { TechService } from '../services/tech.service';
import { TagSelectorComponent } from '../tag-selector/tag-selector.component';
import { OverviewCardComponent } from '../tech-overview-card/overview-card.component';


@Component({
  standalone: true,
  imports: [OverviewCardComponent, PaginationButtonsComponent, MatProgressSpinnerModule, FormsModule, TagSelectorComponent, InboxComponent],
  selector: 'app-tech-overview',
  templateUrl: './tech-overview.component.html',
  styleUrls: ['./tech-overview.component.css'],
})
export class TechOverviewComponent implements OnInit, OnDestroy {
  private techService = inject(TechService);
  private searchParamService = inject(SearchParamService);
  private route = inject(ActivatedRoute);
  private http = inject(HttpClient);

  @Input() filterValue: string = '';
  @Input() selectedEcosystems: Ecosystem[] = [];
  @Input() selectedTags: Tag[] = [];

  ALL_VALUES: string = 'ALL';

  activeTechs: TechDetail[] = [];
  ecosystems: Ecosystem[] = [];
  filter: string = '';
  groupedButtonInfo: Record<string, ButtonInfo> = {};
  selectedEcosystem: string = this.ALL_VALUES;
  selectedTag: string = this.ALL_VALUES;
  tags: Tag[] = [];
  techs?: TechDetail[] | null = null;
  id?: string | null = null;

  isMobileView: boolean = false;
  showAllTechs: boolean = false;

  page: number = 1;
  pageSize: number = 30;
  paginationName = 'page';

  private ngUnsubscribe = new Subject<void>();

  ngOnInit(): void {
    this.getCollabResourceButton();

    fromEvent(window, 'resize')
      .pipe(
        map(() => window.innerWidth),
        startWith(window.innerWidth),
        distinctUntilChanged()
      )
      .subscribe((width) => {
        this.isMobileView = width <= 768;
      });

    combineLatest([this.techService.getTechsDetails(), this.techService.getEcosystems(), this.techService.getTags()])
      .pipe(
        map(([techResponse, ecosystemResponse, tagResponse]) =>
        ({
          techs: mapResponse(techResponse),
          ecosystems: mapResponse(ecosystemResponse),
          tags: mapResponse(tagResponse),
        })),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(({ techs, ecosystems, tags }) => {
        this.techs = techs;
        this.ecosystems = ecosystems;
        this.tags = tags;

        this.selectedEcosystem = window.sessionStorage.getItem("ecoId") ?? this.ALL_VALUES;
        this.selectedTag = window.sessionStorage.getItem("tagId") ?? this.ALL_VALUES;

      });

    this.route.params.subscribe((params) => {
      this.id = params['id'] ?? null;
    });

    this.searchParamService.getNumberedSearchParam(this.paginationName, 1)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(pageNumber => this.page = pageNumber)
  }

  handleSearchValue(value: string) {
    this.filter = value;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setEcosystemFilter(ecosystemId: string) {
    window.sessionStorage.setItem("ecoId", ecosystemId);
    this.selectedEcosystem = ecosystemId;
  }

  setTagFilter(tagId: string) {
    window.sessionStorage.setItem("tagId", tagId);
    this.selectedTag = tagId;
  }

  get filteredTechs() {
    const unfilteredTechs = this.showAllTechs ? this.techs : this.techs?.filter((tech) => !tech.isArchived);

    return unfilteredTechs
      ?.filter(tech => tech.name.toLocaleLowerCase().includes(this.filter?.toLocaleLowerCase() ?? ''))
      .filter(tech => (
        this.selectedTags.length === 0 ||
        tech.tags?.some(t => this.selectedTags.map(st => st.id).includes(t.id))
      ))
      .filter(tech =>
        this.selectedEcosystems.length === 0 ||
        tech.ecosystems?.some(t => this.selectedEcosystems.map(se => se.id).includes(t.id))
      )
      .sort((t1, t2) => t1.name.toLocaleLowerCase() < t2.name.toLocaleLowerCase() ? -1 : 1);
  }

  get pagedTechs() {
    const startIndex = (this.page - 1) * this.pageSize
    const endIndex = (Math.min(this.filteredTechs?.length || 0, startIndex + this.pageSize))

    return this.filteredTechs?.slice(startIndex, endIndex) || null
  }

  async getCollabResourceButton() {
    const url = `${environment.collabBackendApiUrl}/api/resource/allButtoninfo`;
    const params = { url: 'tech' };
    const headers = {
      Authorization: `Bearer ${window.sessionStorage.getItem('JWT')}`
    };

    const observer = {
      next: (response: ButtonInfo[]) => {
        const grouped = response.reduce((prev, next) => {
          const id = next.resource?.split('/')[next.resource.split('/').length - 1] || '';
          return { ...prev, [id]: next };
        }, {} as Record<string, ButtonInfo>);
        this.groupedButtonInfo = grouped;
      },
      error: (error: any) => {
        console.error(error);
      }
    };

    this.http.get<Array<ButtonInfo>>(url, { params, headers }).subscribe(observer);
  }
}

const mapResponse = <TResponse>(response: ArrayApiResponse<TResponse>): TResponse[] => !response.error ? (response.data ? response.data : []) : [];