import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion'
import { JwtModule } from './core/authentication/jwt/jwt.module';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { TechDetailComponent } from './tech-detail/tech-detail.component';
import { TechEditComponent } from './tech-edit/tech-edit.component';
import { TechInteractionCardComponent } from './tech-interaction-card/tech-interaction-card.component';
import { TechOverviewComponent } from './tech-overview/tech-overview.component';
import { TechService } from './services/tech.service';
import { TagEditComponent } from './tag-edit/tag-edit.component';
import { TagOverviewComponent } from './tag-overview/tag-overview.component';
import { TagGenreEditComponent } from './tag-genre-edit/tag-genre-edit.component';
import { TagSelectorComponent } from './tag-selector/tag-selector.component';
import { TagComponent } from './tag/tag.component';
import { overviewDesktopCardComponent } from './tech-overview-desktop-card/overview-desktop-card.component';
import { overviewMobileCardComponent } from './tech-overview-mobile-card/overview-mobile-card.component';
import { CollapsibleComponent } from './collapsible/collapsible.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    TechDetailComponent,
    TechEditComponent,
    TechOverviewComponent,
    TagOverviewComponent,
    TagEditComponent,
    TagGenreEditComponent,
    overviewDesktopCardComponent,
    overviewMobileCardComponent,
    TagSelectorComponent,
    TagComponent,
    CollapsibleComponent,
    TechInteractionCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatExpansionModule,
    JwtModule,
    ToastrModule.forRoot(),
    AppRoutingModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, TechService],
  bootstrap: [AppComponent],
})
export class AppModule {}