<div class="container card shadow-sm p-3 mb-2">
  @if(!inboxedTechs){
  <div>...</div>
  } @else if(inboxedTechs.length){
  <app-collapsible [isCollapsed]="false">
    <div label class="d-flex gap-1">
      <span class="bi bi-inbox"></span>
      Inbox ({{ inboxedTechs.length }})
    </div>
    <div class="pt-1 d-flex flex-wrap gap-1">
      @for (inboxedTech of inboxedTechs; track inboxedTech.id){
      <div class="d-inline-flex border btn inbox-list-item" (click)="goToTech(inboxedTech.slug)">
        <div class="edit-icon">
          <span class="bi bi-pencil-square"></span>
        </div>
        <div class="flex-grow-1 mx-1">
          {{ inboxedTech.name }}
        </div>
      </div>
      }
    </div>
  </app-collapsible>
  } @else {
  <div class="d-flex gap-1"><span class="bi bi-inbox"></span>Empty Inbox</div>
  }
</div>
