import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TechDetail} from '../models/models';
import { TechService } from '../services/tech.service';

@Component({
  selector: 'app-tech-detail',
  templateUrl: './tech-detail.component.html',
  styleUrls: ['./tech-detail.component.css'],
})
export class TechDetailComponent implements OnInit {
  id!: string;
  tech?: TechDetail;
  isArchived?: boolean;

  constructor(
    private techService: TechService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id !== null) {
        this.techService.getTechDetailById(this.id).subscribe({
          next: (tech) => {
            if (tech.data) {
              this.tech = tech.data;
            }
            if (tech.error) {
              return;
            }
            if (tech.data) {
              this.tech = tech.data;
            }
          },
          error: (e) => {},
        });
      }
    });

    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.techService.getTechById(this.id).subscribe({
        next: (tech) => {
          if (tech.data) {
            this.isArchived = tech.data.isArchived;
          }
          if (tech.error) {
            this.isArchived = false;
          }
        },
        error: (e) => {},
      });
    });
  }

  async onEditTech() {
    await this.router.navigate([`tech/edit/${this.id}`]);
  }

  async onDeleteTech() {
    const confirmed = confirm("Are you sure you want to permanently delete this technology?");
    if (confirmed) {
      const confirmed2 = confirm("This action is irreversible, are you SURE?");
      if (confirmed2) {
        this.executeDeleteTech();
      }
    }
  }

  async executeDeleteTech() {
    if (this.id) {
      this.techService.deleteTech(this.id).subscribe();
      await this.router.navigate(['/techs']);
    } else {
      alert('No Current Tech');
    }
  }

  async onArchiveTech() {
    if (this.id) {
      this.techService.archiveTech(this.id).subscribe();
      await this.router.navigate(['/tech']);
    } else {
      alert('No Current Tech');
    }
  }

  async back(): Promise<void> {
    await this.router.navigate([".."]);
  }
}
