<div class="container mt-3">
  <div class="btn-group d-flex mt-4">
    <button type="button" class="btn btn-outline-secondary btn-lg" (click)="onClickedTagEdit()">
      <i class="bi bi-plus-lg"></i>
      Add Tag
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary btn-lg"
      (click)="onClickedTagGenreEdit()"
      >
      <i class="bi bi-plus-lg"></i>
      Add Genre
    </button>
  </div>

  <div class="py-2">
    <hr class="hr hr-blurry" />
  </div>

  <div class="container card shadow-sm p-3">
    <h2 class="my-2">Current Tags and Genres</h2>
    @for (tagGenre of tagGenres; track tagGenre) {
      <div class="mt-2">
        <div class="d-flex justify-content-between">
          <h5>{{ tagGenre.name }}</h5>
          <button
            type="button"
            class="btn p-0 pb-1 ms-1"
            aria-label="Edit"
            (click)="onClickedTagGenreEdit(tagGenre)"
            >
            Edit
            <span class="bi bi-pencil-fill"></span>
          </button>
        </div>
        <hr class="hr hr-blurry mt-0" />
        @if (getTagsOfGenre(tagGenre).length > 0) {
          <div>
            <div class="d-flex flex-wrap align-items-center gap-1 my-3">
              <span class="bi bi-tags mr-2"></span>
              @for (tag of getTagsOfGenre(tagGenre); track tag) {
                <span
                  class="badge gap d-flex align-items-center"
                  [style.background-color]="tagGenre?.backgroundColor" [style.color]="tagGenre?.textColor"
                  >
                  {{ tag.name }}
                  <div class="ms-2">
                    <button
                      type="button"
                      class="btn p-0"
                      aria-label="Edit"
                      (click)="onClickedTagEdit(tag)"
                      >
                      <span class="bi bi-pencil-fill"></span>
                    </button>
                  </div>
                </span>
              }
            </div>
          </div>
        } @else {
          <h6 class="text-muted">No tags</h6>
        }
      </div>
    }
  </div>
</div>
