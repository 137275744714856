import { Component, Input } from '@angular/core';
import { ButtonInfo } from '../models/models';

@Component({
  selector: 'tech-interaction-card',
  templateUrl: './tech-interaction-card.component.html',
  styleUrls: ['./tech-interaction-card.component.css'],
})
export class TechInteractionCardComponent {
  @Input() buttonInfo?: ButtonInfo;

  constructor(
  ) {}

  ngOnInit(): void {
  }
}