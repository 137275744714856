import { Routes } from '@angular/router';
import { TagEditComponent } from "./tag-edit/tag-edit.component";
import { TagGenreEditComponent } from './tag-genre-edit/tag-genre-edit.component';
import { TagOverviewComponent } from './tag-overview/tag-overview.component';
import { TagSelectorComponent } from "./tag-selector/tag-selector.component";
import { TechDetailComponent } from './tech-detail/tech-detail.component';
import { TechEditComponent } from './tech-edit/tech-edit.component';
import { TechOverviewComponent } from './tech-overview/tech-overview.component';

enum Path {
  Tech = 'tech',
  Tag = 'tag',
  TagGenre = 'tagGenre'
}

export const routes: Routes = [
  { path: '', redirectTo: '/tech', pathMatch: 'full' },
  {
    path: `${Path.Tag}`,
    component: TagOverviewComponent,
  },
  {
    path: `${Path.Tag}/add`,
    component: TagEditComponent,
  },
  {
    path: `${Path.Tag}/edit/:id`,
    component: TagEditComponent,
  },
  {
    path: `${Path.Tech}/tagoverview/:id`,
    component: TagSelectorComponent,
  },
  {
    path: Path.Tech,
    component: TechOverviewComponent,
  },
  {
    path: `${Path.Tech}/add`,
    component: TechEditComponent,
  },
  {
    path: `${Path.Tech}/edit/:slug`,
    component: TechEditComponent,
  },
  {
    path: `${Path.Tech}/:slug`,
    component: TechDetailComponent,
  },
  {
    path: `${Path.TagGenre}/add`,
    component: TagGenreEditComponent,
  },
  {
    path: `${Path.TagGenre}/edit/:id`,
    component: TagGenreEditComponent,
  },
];
