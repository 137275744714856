import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtLoaderComponent } from './jwt-loader/jwt-loader.component';
import { JwtProviderService } from './jwt-provider/jwt-provider.service';

@NgModule({
  declarations: [JwtLoaderComponent],
  imports: [CommonModule],
  exports: [JwtLoaderComponent],
  providers: [JwtProviderService],
})
export class JwtModule {}
